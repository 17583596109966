import commonHelper from '@/app/utility/common.helper.utility';
import FmsSectorDetails from './fmsSectorDetails';
import { ModelSelect } from 'vue-search-select';
import MasterProjectModule from '../fmsMasterProject';
export default {
  name: 'FmsSector',
  components: {
    ModelSelect,
    FmsSectorDetails,
    MasterProjectModule
  },
  props: {
    showFmsSectorList: Boolean,
    fmsMasterProjId: Number,
    fmsMasterProjName: String
  },
  watch: {
    currentPage: function() {
      this.getFmsSectorList();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getFmsSectorList();
    }
  },
  data() {
    return {
      unsubscribe: null,
      showSelectedSectorRecord: false,
      showMasterProjectModule: false,
      loader: false,
      editMode: false,
      updateMode: false,
      totalRows: null,
      payload: {},
      currentPage: 1,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      masterProj: {},
      fmsMasterProj: {
        value: null,
        text: null
      },
      fmsMasterProjData: [
        {
          value: null,
          text: null
        }
      ],
      fmsSectorName: '',
      fmsSectorCode: '',
      fmsSectorData: [],
      fmsSectorFields: [
        {
          key: 'master_project_name',
          label: 'Master Project'
        },
        {
          key: 'crm_sector_name',
          label: 'CRM Sector'
        },
        {
          key: 'fms_sector_code',
          label: 'Sector Code'
        },
        {
          key: 'fms_sector_name',
          label: 'Sector Name'
        }
      ],
      defaultValue: {
        text: null,
        value: null
      },
      routeName: this.$router.currentRoute.name
    };
  },
  mounted() {
    if (this.showFmsSectorList) {
      this.fmsMasterProj.text = this.fmsMasterProjName;
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.showSelectedSectorRecord = true;
          this.timeoutEdit = setTimeout(() => {
            this.eventBus.$emit('addCase', !this.editmode);
          }, 0);
        }
        if (actionName === 'download') {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'unitInventory/getFmsSectorList',
            'fms-sector',
            () => (this.loader = false)
          );
        }
      }
    });
  },
  methods: {
    openMasterProjectList() {
      this.showMasterProjectModule = true;
    },
    selectMasterProjectList(data) {
      this.fmsMasterProj = {
        value: data.fms_master_prj_id,
        text: data.fms_master_prj_name
      };
      this.showMasterProjectModule = false;
    },
    hideMasterProjectModule() {
      this.showMasterProjectModule = false;
    },
    getFmsSectorList() {
      this.payload = {
        _limit: this.perPage,
        _page: this.currentPage - 1,
        master_project_id: this.fmsMasterProjId
          ? this.fmsMasterProjId
          : this.fmsMasterProj.value,
        fms_sector_code: this.fmsSectorCode,
        fms_sector_name: this.fmsSectorName
      };
      this.loader = true;
      this.$store
        .dispatch('unitInventory/getFmsSectorList', this.payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.fmsSectorData = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    rowSelected(rowData) {
      if (this.$router.currentRoute.name === 'fmsSector') {
        this.showSelectedSectorRecord = true;
        setTimeout(() => {
          this.eventBus.$emit('showRecordDetails', rowData);
        }, 0);
      } else {
        this.$emit('selectFmsSector', rowData);
      }
    },
    updateList() {
      this.getFmsSectorList();
    },
    clearFilters() {
      this.fmsMasterProj = this.defaultValue;
      this.fmsSectorName = '';
      this.fmsSectorCode = '';
      this.totalRows = null;
      this.fmsSectorData = [];
    },
    hideSectorRecord() {
      this.showSelectedSectorRecord = false;
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === this.fmsMasterProj.value) {
        this.fmsMasterProj = this.defaultValue;
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
