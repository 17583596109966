import { ModelSelect } from 'vue-search-select';
import appStrings from '@/app/utility/string.utility';
import MasterProjectModule from '../../fmsMasterProject';
export default {
  name: 'FmsSectorDetails',
  components: {
    ModelSelect,
    MasterProjectModule
  },
  watch: {},
  data() {
    return {
      showSelectedSectorRecord: false,
      showMasterProjectModule: false,
      loader: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      editMode: false,
      updateMode: false,
      crmSector: {
        value: null,
        text: null
      },
      fmsMasterProj: {
        value: null,
        text: null
      },
      fmsMasterProjData: [],
      fmsSectorName: null,
      fmsSectorCode: null,
      showValueSetModal: false,
      parent_value_set_id: null,
      fmsSectorId: null,
      defaultValue: {
        text: null,
        value: null
      }
    };
  },
  mounted() {
    this.eventBus.$off('showRecordDetails');
    this.eventBus.$on('showRecordDetails', recordDetails => {
      this.fmsSectorCode = recordDetails.fms_sector_code;
      this.fmsSectorId = recordDetails.fms_sector_id;
      this.fmsSectorName = recordDetails.fms_sector_name;
      this.crmSector = {
        value: recordDetails.crm_sector_id,
        text: recordDetails.crm_sector_name
      };
      this.fmsMasterProj = {
        value: recordDetails.master_project_id,
        text: recordDetails.master_project_name
      };
    });
    this.eventBus.$off('addCase');
    this.eventBus.$on('addCase', editMode => {
      this.updateMode = editMode;
    });
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save') {
          this.addAndEditFmsSector();
        }
        if (actionName === 'update') {
          this.updateMode = true;
        }
        if (actionName === 'upload') {
          this.eventBus.$emit('commonUpload', { id: this.fmsSectorId });
        }
      }
    });
  },
  methods: {
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.CRM_SECTOR) {
        this.crmSector = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
      }
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.parent_value_set_id = null;
      if (this.vsetCode === appStrings.VALUESETTYPE.CRM_SECTOR) {
        this.parent_value_set_id = this.fmsMasterProj.value;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    openMasterProjectList() {
      this.showMasterProjectModule = true;
    },
    selectMasterProjectList(data) {
      this.fmsMasterProj = {
        value: data.fms_master_prj_id,
        text: data.fms_master_prj_name
      };
      this.showMasterProjectModule = false;
    },
    hideMasterProjectModule() {
      this.showMasterProjectModule = false;
    },
    addAndEditFmsSector() {
      const payload = {
        fms_sector_name: this.fmsSectorName,
        fms_sector_code: this.fmsSectorCode,
        fms_sector_id: this.fmsSectorId,
        master_project_id: this.fmsMasterProj.value,
        crm_sector_id: this.crmSector.value
      };
      this.loader = true;
      this.$store
        .dispatch('unitInventory/addAndEditFmsSector', payload)
        .then(response => {
          this.$emit('updateList');
          this.updateMode = false;
          if (response.status === 200) {
            this.loader = false;
            this.showAlert = true;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.loader = false;
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(err => {
          this.err = err;
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === this.crmSector.value) {
        this.crmSector = this.defaultValue;
      } else if (vsetCode === this.fmsMasterProj.value) {
        this.fmsMasterProj = this.defaultValue;
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
